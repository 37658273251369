import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  AccordionDetails,
  Button,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import React, { ChangeEvent, Fragment, ReactElement, useState } from "react";
import { useHierarchicalMenu } from "react-instantsearch";

import { Content, useContent } from "../ContentProvider";

const Accordion = styled(MuiAccordion)({
  border: 0,
  boxShadow: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
});

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  borderBottom: 0,
  marginBottom: -1,
  minHeight: 56,
  "&.Mui-expanded": {
    minHeight: 56,
  },
  "& > .MuiAccordionSummary-content": {
    margin: theme.spacing(1.5, 1.5, 1.5, 0),
  },
}));

const CountryAccordian = (): ReactElement => {
  const { t } = useTranslation(["regions"]);
  const { countries, topic, host } = useContent();
  const [expanded, setExpanded] = useState<string | false>(false);
  let availableCountryNames: string[] = [];
  // Only collect available countries if a topic has been set. Thus reducing the calls on Algolia.
  if (topic) {
    const { items } = useHierarchicalMenu({
      attributes: ["locations.lvl0"],
      limit: 1000,
    });
    availableCountryNames = items.map((item) => item.label);
  }
  const handleChange =
    (panel: string) =>
    (_event: ChangeEvent, isExpanded: boolean): void => {
      setExpanded(isExpanded ? panel : false);
    };

  const regions: {
    name: string;
    localizedName: string;
    countries: Content["countries"];
  }[] = countries.reduce((result, country) => {
    const available =
      availableCountryNames.find(
        (countryName) =>
          countryName.toLowerCase() === country.name.toLowerCase(),
      ) != null;

    if (!topic || available) {
      const region = result.find((region) => region.name === country.region);
      if (region) {
        region.countries.push(country);
      } else {
        result.push({ name: country.region, countries: [country] });
      }
    }
    return result;
  }, []);

  return (
    <>
      {regions.map((region) => (
        <Fragment key={region.name}>
          <Accordion
            expanded={expanded === region.name}
            onChange={handleChange(region.name)}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  color={host?.useDefaultLinkStyles ? undefined : "secondary"}
                />
              }
            >
              <Typography
                variant="h6"
                color={host?.useDefaultLinkStyles ? undefined : "secondary"}
                component="h4"
              >
                {t(region.name, { ns: "regions" })}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={1.5} flexWrap="wrap" direction="row" useFlexGap>
                {region.countries.map((country) => (
                  <NextLink
                    legacyBehavior
                    key={country.code}
                    href={`/countries/${country.code.toLowerCase()}${
                      topic ? `/topics/${topic.slug}` : ""
                    }`}
                    prefetch={false}
                    passHref
                  >
                    <Button
                      variant="contained"
                      color="inherit"
                      sx={{
                        borderRadius: 1.5,
                        fontWeight: 400,
                        color: "text.primary",
                      }}
                    >
                      {country.localizedName}
                    </Button>
                  </NextLink>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Fragment>
      ))}
    </>
  );
};

export default CountryAccordian;
