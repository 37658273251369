import { Box, SvgIcon, SvgIconProps, Typography } from "@mui/material";
import React, { ReactElement } from "react";

interface HighlightProps {
  title: string;
  description: string;
  variant?: "default" | "paper";
  Icon: (props: SvgIconProps) => ReactElement;
}

const Highlight = (props: HighlightProps): ReactElement => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "80px 1fr",
        columnGap: 3,
        mx: 0,
        my: 2,
        p: 2,
        borderRadius: 2.5,
        backgroundColor:
          props.variant == "paper" ? "background.paper" : "background.default",
        color: props.variant == "paper" ? "primary.main" : "secondary.main",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gridRow: "1 / span 2",
          fontSize: "4rem",
          mx: 1,
        }}
      >
        <SvgIcon fontSize="inherit">
          <props.Icon />
        </SvgIcon>
      </Box>
      <Typography
        sx={{
          gridColumn: "2",
          gridRow: "1",
        }}
        variant="h6"
        component="h3"
      >
        {props.title}
      </Typography>
      <Typography
        sx={{
          gridColumn: "2",
          gridRow: "2",
        }}
      >
        {props.description}
      </Typography>
    </Box>
  );
};

export default Highlight;
