import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import React, { ReactElement } from "react";

import { useContent } from "../ContentProvider";

const TopicLinks = (): ReactElement => {
  const { topics } = useContent();
  const { t } = useTranslation(["common", "topics"]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" color="secondary">
          {t("Quick link")}
        </Typography>
        <Box pt={1} pb={2}>
          <NextLink
            legacyBehavior
            href="/topics/suicidal-thoughts"
            passHref
            prefetch={process.env.NODE_ENV === "production"}
          >
            <Button
              color="secondary"
              variant="contained"
              size="large"
              endIcon={<ArrowRightAltRoundedIcon />}
            >
              {t("Suicidal Thoughts")}
            </Button>
          </NextLink>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack
          marginTop={1}
          spacing={1.5}
          flexWrap="wrap"
          direction="row"
          useFlexGap
        >
          {topics.map((topic) => (
            <NextLink
              legacyBehavior
              key={topic.slug}
              href={`/topics/${topic.slug}`}
              prefetch={false}
              passHref
            >
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  borderRadius: "6px",
                  fontWeight: 400,
                  backgroundColor: "background.paper",
                  boxShadow: "none",
                }}
              >
                {t(topic.name, { ns: "topics" })}
              </Button>
            </NextLink>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TopicLinks;
